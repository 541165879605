@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  display: flex;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  color: #fff;
  z-index: 4;
  background: transparent;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 10;
  position: fixed;
  top: 0;
  backdrop-filter: blur(8px);
}

.left-nav {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.logo {
  width: 18%;
}

.navbar .nav-text a {
  color: #e61b5d;
  font-size: 1.6rem;
  font-family: "Nunito", sans-serif;
  text-decoration: none;
  padding: 10px;
}

.nav-links {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.item {
  text-decoration: none;
  text-align: center;
  color: #000;
  font-size: 1rem;
  font-family: "Nunito", sans-serif;  
  padding: 10px 10px;
  margin: auto;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.item:hover {
  color: #ff6c98;
  font-weight: 600;
}

.get-btn {
  margin: auto 10px;
  padding: 10px 10px;
  background-color: #ff6c98;
  border: none;
  border-radius: 12px;
}

.get-btn a {
  font-family: "Nunito", sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 0.8rem;
  color: #ffffff;
  text-decoration: none;
}

.mobile-menu-icon {
  display: none;
}

.nav-links-mobile {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  list-style: none;
  background-color: #ffffff;
  z-index: 3;
}

@media (max-width: 768px) {
  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
  }

  .left-nav {
    margin-left: 0;
    margin-right: 10px;
  }

  .logo {
    width: 15%;
  }

  .nav-text {
    font-size: 1.6rem;
  }

  .nav-links {
    display: none;
  }

  .hamburger-menu{
    display: flex;
    font-size: 2.2rem;
    color: #e61b5d;
    background-color: transparent;
    border: none;
    outline: none;
    justify-content: flex-end;
    cursor: pointer;
  }

  .nav-links-mobile {
    top: 60px;
    right: 0;
    width: 35%;
  }

  .item {
    padding: 5px;
  }

  .get-btn {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
