.error-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error-404 img {
  max-width: 30%;
  object-fit: cover;
  margin-top: 5rem;
}


.return-btn {
  padding: 0.75rem 1.5rem;
  background-color: #ff6c98;
  border: none;
  border-radius: 10px;
  color: #fff;
}

.error-404 h1{
    font-size: 3rem;
    font-weight: 700;
    padding: 0.5rem 2rem;
    font-family: "Plus Jakarta sans", sans-serif;
}

.error-404 h2{
    font-size: 1.5rem;
    font-weight: 400;
    font-family: "Nunito", sans-serif;
    padding: 0.5rem 2rem;
}

.link-home{
    text-decoration: none;
}

.return-btn {
    margin: 1rem;
    padding: 0.75rem 1.5rem;
    background-color: #ff6c98;
    border: none;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
  }
  
  @media screen and (max-width: 768px) {
    .error-404 h2{
        text-align: center;
    }
  }