.hero-wrapper {
  margin-top: 60px;
}
.hero-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: url("/public/images/hero_background.svg"),
    linear-gradient(180deg, #ff007f 4.43%, #e90c55 103.92%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center ;
  margin-top: 60px;
}

.hero-head {
  align-items: center;
  justify-content: center;
}
.head-text {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 700;
  font-size: 3.5rem;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  text-align: center;
  color: #fff;
  margin-top: 100px;
}

.hero-subhead {
  margin-top: 27px;
  align-items: center;
  justify-content: center;
}

.hero-subhead-text {
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  letter-spacing: 0.02em;
  text-transform: lowercase;
  color: #ebebeb;
}

.cta {
  margin-top: 30px;
  background-color: #fff;
  padding: 13px 30px;
  border: none;
  border-radius: 12px;
  z-index: 3;
}

.cta a {
  font-family: "Nunito", sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
  text-decoration: none;
  color: #ff6c98;
}

.space {
  margin-top: 30px;
  z-index: 3;
}

.space img {
  margin: 10px;
}

.user-image {
  display: flex;
  justify-content: center;
  margin-top: -220px;
  z-index: 2;
  max-width: 100%;
  height: auto;
}

.male-pic,
.female-pic {
  height: auto;
  max-width: 100%;
  object-fit: contain;
  margin-bottom: 75px;
}

.male-pic {
  margin-right: 55%;
}

.female-pic.animate {
  position: relative;
  animation: slideright 1.5s;
  animation-direction: alternate;
}

@keyframes slideright {
  0% {
    right: -200px;
  }
  100% {
    right: 0;
  }
}

@media only screen and (min-width: 1200px) {
  .hero-head {
    max-width: 40%;
  }

  .hero-subhead{
    max-width: 30%;
  }
}

@media (min-width : 992px) and (max-width : 1200px) {
  .hero-head {
    max-width: 50%;
  }
  .head-text {
    font-size: 2.75rem;
  }

  .hero-subhead{
    max-width: 30%;
    margin-top: 10px;
  }
  
  .hero-subhead-text{
    line-height: 24px;
    font-size: 1rem;
  }

  .cta {
    margin-top: 20px;
    padding: 12px 12px;
  }
  
  .cta a {
    font-size: 1rem;
  }

  .space {
    margin-top: 20px;
    width : 40%;
  }
  
  .space img {
    margin: 2px;
    width : 15%;
  }

  .user-image {
    margin-top: -210px;
    z-index: 2;
    max-width: 40%;
  }
  
  .male-pic,
  .female-pic {
    max-width: 65%;
    margin-bottom: 60px;
  }

  .male-pic {
    margin-right: 75%;
  }
}


.social-icons img {
  height: 48px;
  object-fit: scale-down;
}

@media (min-width: 768px) and (max-width: 992px) {
  .hero-head {
    max-width: 50%;
  }

  .head-text {
    font-size: 2.5rem;
  }

  .hero-subhead{
    max-width: 40%;
    margin-top: 10px;
  }
  
  .hero-subhead-text{
    line-height: 24px;
    font-size: 1rem;
  }

  .cta {
    margin-top: 10px;
    padding: 12px 12px;
  }
  
  .cta a {
    font-size: 1rem;
  }

  .space {
    margin-top: 10px;
    width : 40%;
  }
  
  .space img {
    margin: 2px;
    width : 15%;
  }

  .user-image {
    margin-top: -150px;
    z-index: 2;
    max-width: 40%;
  }
  
  .male-pic,
  .female-pic {
    max-width: 65%;
    margin-bottom: 60px;
  }
  
  .male-pic {
    margin-right: 80%;
  }
}

@media (min-width: 600px) and (max-width : 768px){
  .hero-head {
    max-width: 70%;
  }

  .head-text {
    font-size: 2.5rem;
  }

  .hero-subhead{
    max-width: 60%;
    margin-top: 10px;
  }
  
  .hero-subhead-text{
    line-height: 24px;
    font-size: 1rem;
    font-weight: bold;
  }

  .cta {
    margin-top: 10px;
    padding: 12px 12px;
  }
  
  .cta a {
    font-size: 1rem;
  }

  .space {
    margin-top: 10px;
    width : 40%;
  }
  
  .space img {
    margin: 2px;
    width : 15%;
  }
  .user-image {
    margin-top: -140px;
    z-index: 2;
    max-width: 40%;
  }
  
  .male-pic,
  .female-pic {
    max-width: 65%;
    margin-bottom: 60px;
  }
  
  .male-pic {
    margin-right: 96%;
  }
}

@media only screen and (max-width: 600px) { 
  .hero-head {
    max-width: 90%;
  }

  .head-text {
    font-size: 2.2rem;
  }

  .hero-subhead{
    max-width: 70%;
    margin-top: 10px;
  }
  
  .hero-subhead-text{
    line-height: 24px;
    font-size: 1rem;
  }

  .cta {
    margin-top: 10px;
    padding: 8px 8px;
  }
  
  .cta a {
    font-size: 0.7rem;
  }

  .space {
    margin-top: 10px;
    width : 40%;
  }
  
  .space img {
    margin: 2px;
    width : 20%;
  }
  .user-image {
    margin-top: -90px;
    z-index: 2;
    max-width: 40%;
  }
  
  .male-pic,
  .female-pic {
    max-width: 65%;
    margin-bottom: 60px;
  }
  
  .male-pic {
    margin-right: 95%;
  }

  .social-icons img {
    height: 24px;
    object-fit: scale-down;
  }

}

