.blog-card {
  background: #ffffff;
  box-shadow: 0 -1px 4px rgba(255, 108, 152, 0.25),
    0 1px 4px rgba(255, 95, 143, 0.25);
  border-radius: 12px;
  margin: 20px;
  list-style: none;
  transition: transform 0.3s ease;
}

.blog-card:hover {
  transform: scale(1.04);
}

.blog-card-link {
  text-decoration: none;
}

.blog-card-title {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 23px;
  letter-spacing: 0.02em;
  padding: 10px;
  color: #000000;
  text-decoration: none !important;
}

.blog-card-image-placeholder {
  width: 100%;
  object-fit: contain;
  padding: 8px;
}

.blog-card-image{
  width: 100%;
  object-fit: cover;
  padding: 8px;
}

.blog-card-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

.blog-card-footer-text {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #919191;
  text-decoration: none;
}

.img-container {
  position: relative;
}

.categories {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  padding: 5px;
}

.category {
  display: block;
  margin: 0.5rem 1.5rem;
  padding: 0.5rem 0.5rem;
  text-align: center;
  color: #fff;
  font-size: 0.75rem;
  font-family: "Nunito", sans-serif;
  background-color: #3f3b3b;
  opacity: 0.6;
  border-radius: 0.5rem;
}