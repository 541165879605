@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

.footer-section {
    background-color: #FF5F8F;
    color: #ffffff;
    margin-top: 50px;
}

.footer-top {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.footer-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.contact-info {
    margin-top: 45px;
}

.contact-head {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 1.1rem;
    font-weight: 600;
    padding: 5px;
}

.contact-list {
    list-style: none;
}

.info-text {
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    font-size: 0.9rem;
    padding: 3px;
}

.social-icons img {
    margin-top: 8px;
    border-radius: 10px;
    padding: 2px;
}

.link-info {
    margin-top: 45px;
}

.link-head {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 1.1rem;
    font-weight: 600;
    padding: 5px;
}

.link-list {
    list-style: none;
}

.link-text {
    text-decoration: none;
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    font-size: 0.9rem;
    color: #FFFFFF;
    padding: 5px;
    cursor: pointer;
}

.link-text:hover {
    text-decoration: underline;
}

.email-info {
    margin-top: 70px;
    padding: 10px;
}

.email-head {
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 36px;
    margin: auto;
    text-align: center;
}

.email-sub {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 0;
    gap: 10px;
}

.input-btn {
    width: 300px;
    height: 48px;
    background: #FFFFFF;
    border-radius: 12px;
    border: none;
    outline: none;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 36px;
    margin: auto;
    padding-left: 15px;
}

.input input {
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 36px;
    margin: auto;
    text-align: center;
}

::placeholder {
    font-family: 'Nunito', sans-serif;
    background: #FFFFFF;
    font-weight: 600;
    font-size: 1rem;
    line-height: 36px;
    margin: auto;
    border: none;
    letter-spacing: 0.15em;
    text-align: left;
}

.sub-btn {
    width: 128px;
    height: 48px;
    background: #FFFFFF;
    border-radius: 12px;
    border: none;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 27px;
    color: #FF5F8F;
    padding: 2px;
    cursor: pointer;
}

.gap-line {
    width: 80%;
    margin: auto;
    margin-top: 35px;
}

.policy-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: center;
    margin-top: 25px;
    padding: 10px;
    list-style: none;
}

.policy-text {
    text-decoration: none;
    color: #ffff;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 25px;
    text-transform: capitalize;
    list-style: none;
    margin-right: 10px;
    text-align: center;
    padding: 6px;
}

.policy-text:hover {
    text-decoration: underline;
}

.copyright-info {
    margin-top: 25px;
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    flex-direction: row;
}

.copyright-text {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 25px;
    text-transform: capitalize;
    list-style: none;
    text-align: center;
    padding: 6px;
}

.social-icons img {
    height: 48px;
    object-fit: scale-down;
}

@media screen and (max-width : 968px) {
    .footer-section {
        height: auto;
    }

    .footer-top {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
    }

    .email-info {
        margin-top: 30px;
    }

    .footer-bottom {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
    }

    .gap-line {
        margin-top: 15px;
    }

    .email-info {
        margin-top: 45px;
    }

    .policy-info {
        margin-top: 5px;
    }

    .copyright-info {
        margin-top: -15px;
    }

    .email-sub {
        flex-direction: column;
    }

    .sub-btn {
        margin: 8px;
    }
}

@media screen and (max-width : 400px) {
    .input-btn {
        width: 80%;
    }

    .contact-info {
        margin-top: 15px;
    }

    .link-info {
        margin-top: 15px;
    }

    .email-info {
        margin-top: 0;
    }

    .social-icons {
        margin-top: 0;
    }

    .sub-btn {
        width: 40%;
    }

    .policy-info {
        flex-direction: column;
        margin-top: 0;
    }

    .policy-text {
        padding: 2px;
    }

    .copyright-info {
        flex-direction: column;
        padding-top: 10px;
    }

    .copyright-text {
        padding: 2px;
    }
}