@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.blog-hero {
  position: relative;
  margin-top: 60px;
}

.bh-head {
  font-family: "Plus Jakarta Sans", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2.6rem;
  line-height: 55px;
  text-align: center;
  letter-spacing: 0.02em;
  padding-top: 60px;
  color: #1e1e1e;
}

.bh-subhead {
  max-width: 60%;
  margin: auto;
  margin-top: 20px;
}

.bh-subhead-text {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 27px;
  text-align: center;
  color: #545454;
}

.search-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 15px 0;
  gap: 10px;
}


.search-input {
  border: 1px solid #8d8d8d;
  border-radius: 12px;
  padding: 20px 20px 20px 30px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  color: #505050;
  padding: 10px;
  width: 400px;
}

.search-input input {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  color: #505050;
  padding: 10px;
}

::placeholder {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  color: #505050;
  padding: 10px;
}

.search-btn {
  cursor: pointer;
  background: #ff5f8f;
  border-radius: 12px;
  border: none;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  color: #ffffff;
  width: 120px;
  padding: 10px 10px 10px 10px;
}

.blogview-section {
  margin-top: 30px;
}

.bv-head {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: center;
}

.bv-head-title {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 27px;
  color: #9f9f9f;
  padding: 10px;
}

.blogcard-wrapper {
  max-width: 90%;
  margin: 0 auto;
  margin-top: 20px;
  display: grid;
  gap: 20px;
}

.pagenav-section {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left-pagenav {
  margin: auto;
}

.left-pagenav-text {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 27px;
  color: #1e1e1e;
  padding: 10px;
}

.pagination {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 5px;
  gap: 10px;
}

.pagination a {
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 29px;
}

.pagination a.active {
  background: #ff5f8f;
  border-radius: 5px;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}

.right-pagenav {
  margin: auto;
  cursor: pointer;
}

.right-pagenav-text {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 27px;
  color: #504ed0;
  padding: 10px;
  ;
}

.no-results-message {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 27px;
  color: #1e1e1e;
  padding: 10px;
  text-align: center;
  align-items: center;
}

@media (min-width: 600px) {
  .blogcard-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  .blogcard-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 992px) {
  .bh-subhead {
    max-width: 80%;
  }
}

@media (max-width: 600px) {
  .bh-subhead {
    max-width: 90%;
  }

  .search-input {
    max-width: 60%;
  }

  .search-btn {
    max-width: 70%;
  }

  .search-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 360px) {
  .bv-head {
    gap: 15px;
  }
}

@media(max-width :800px) {
  .pagenav-section {
    flex-direction: column;
  }

  .pagination {
    justify-content: center;
  }
}