
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text&family=Playfair:opsz,wght@5..1200,400;5..1200,700;5..1200,800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Source+Serif+4:opsz,wght@8..60,300;8..60,400&display=swap');



.blog-content {
  position: relative;
  margin-top: 70px;
}
.blog-content-article {
  max-width: 1000px;
  background: #ffffff;
  box-shadow: 0 -1px 56px rgba(255, 95, 143, 0.25),
    0 1px 59px rgba(255, 95, 143, 0.15);
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 0.25rem 2rem 5rem;
}


.blogcontent-footer {
  margin-top: 30px;
}

.blogcontent-footer-text {
  font-family: "Nunito", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 168%;
  letter-spacing: 0.025em;
  color: #1e1e1e;
  padding-left: 5rem;
}

.blog-card-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  max-width: 90%;
  margin: auto;
}

/* editorjs rendered css start*/
.editor-delimiter {
  width: 200px;
  margin: 6rem auto 2rem;
  border-bottom: 4px dotted hotpink;
}


.editor-embed {
  margin: 1rem 0;
  width: 100%;
  font-size: medium;
  font-family: "Nunito", serif;
  color: #505050;
}

.editor-header {
  padding: 1.5rem 0 0.5rem;
  font-family: 'Source Serif 4', serif;

  /*font-family: 'Playfair', serif;*/
  /*font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;*/
}

.editor-para {
  font-family: 'Source Serif 4', serif;
  /*font-family: 'DM Serif Text', serif;*/
  /*font-family: 'Source Serif 4', serif;*/
  font-weight: 300;
  font-size: 18px;
  padding: 0.4rem 2rem;
}

.editor-image {
  margin: 1rem 0;
  width: 100%;
}

.editor-quote {
  font-family: 'Playfair', serif;
  font-size: 1.2rem;
  padding: 0 1.5rem;
  font-style: italic;
  text-align: center;
}

.editor-image {
  display: flex;
  flex-direction: column;
  margin:auto;

  > img {
    align-self: center;

    padding: 5px;
    object-fit: cover;
    height: 100%;
    width: 60%;

  }
  >figcaption{
    align-self: center;

  }
}

.stretched > img{
  width: 100%;
  padding: 5px;
  object-fit: cover;
  height: 100%;
}


.withBackground{
  width: 100%;
  border-radius: 12px;
  padding: 5px;
  object-fit: cover;
  height: 100%;
  background-color: rgba(255, 108, 152, 0.31);

}

.withBorder>img{
  border: 1px solid #ddd;
  border-radius: 4px;
}


.editor-para::selection{
  color: black;
  background-color: pink;
}

.editor-list {
  font-family: "Nunito", serif;
  font-size: 1rem;
  padding: 0.2rem 3rem;
  letter-spacing: 0.45px;
  opacity: 0.8;
}

/* editorjs rendered css end */

#blog-title {
  color: #1e1e1e;
  margin: auto;
  font-size: 1.75rem;
  font-family: Plus Jakarta Sans, serif;
  font-weight: 700;
  letter-spacing: 0.88px;
  max-width: 75%;
  text-align: center;
  padding-top: 2rem;
}

.blogimg-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.blogimg-container img {
  object-fit: cover;
  border-radius: 12px;
  max-width: 100%;
}

.blogcontent-detail {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.75rem auto auto;
}

.blogcontent-detail-text {
  border-radius: 7px;
  background: #f1f1f1;
  color: #505050;
  font-size: 16px;
  font-family: "Nunito", serif;
  font-weight: 600;
  letter-spacing: 0.32px;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
}

.blogitem-author {
  display: flex;
}

.blogitem-author img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
}

.blogitem-author-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .blogcontent-footer-text {
    text-align: center;
    padding-left: 0;
  }
  .blog-content h1 {
    font-size: 1.5rem;
    max-width: 100%;
  }
  .blog-content-article {
    max-width: 700px;
    padding: 0.25rem 1.8rem 5rem;
  }
  .editor-para {
    font-size: 1.3rem;
    padding: 0.4rem .1rem;
  }
}
